<template>
    <div class="col-md-12">
        <div class="card border-0 bg-gray-800 text-white">
            <div class="card-body">
                <div class="text-gray-500">
                    <b>{{ $t('str.msg.analysis.checkpoints.perday').toUpperCase() }}</b>
                </div>
            </div>
            <div class="card-body pe-3 ps-0 py-0">
                <apexchart :key="componentKey" id="chart" ref="chart" type="bar" width="100%" height="300" :options="chart.options" :series="chart.series"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import pt from 'apexcharts/dist/locales/pt-br.json'
import en from 'apexcharts/dist/locales/en.json'
import moment from 'moment'
export default {
    name: 'CheckpointsPerDay',
    props: ['data', 'redirect', 'locale'],
    watch: {
        data: {
            deep: true,
            handler(newData) {
                if (newData) {
                    this.processData(newData)
                }
            },
        },
        locale: {
            immediate: true,
            handler() {
                this.chart.options.chart.defaultLocale = this.locale || 'pt-br'
                this.processData(this.data)
                ApexCharts.exec('chart', 'updateLocale', this.locale)
            },
        },
    },
    data() {
        return {
            componentKey: 0,
            chart: {
                series: [],
                options: {
                    colors: ['#70BBFF', '#32a932', '#db1d04'],
                    chart: {
                        type: 'bar',
                        height: 350,
                        toolbar: { show: false },
                        locales: [pt, en],
                        defaultLocale: this.locale || 'pt-br',
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent'],
                    },
                    xaxis: {
                        type: 'category',
                        categories: [],
                        labels: {
                            style: { colors: '#fff' },
                        },
                    },
                    yaxis: {
                        title: {
                            text: this.$t('str.checkpoints'),
                        },
                        labels: {
                            style: { colors: '#fff' },
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    tooltip: {
                        y: {
                            formatter: val => `${val} ${this.$t('str.checkpoints')}`,
                        },
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'right',
                        labels: {
                            colors: '#fff',
                        },
                    },
                },
            },
        }
    },
    methods: {
        processData(data) {
            if (!data || Object.keys(data)?.length === 0) return

            const categories = []
            const readSeries = []
            const unreadSeries = []
            const totalSeries = []

            const readData = {}
            const unreadData = {}
            const totalData = {}

            // Mapeia os dados recebidos para evitar duplicação de datas
            data.read.forEach(item => {
                const date = item[0]
                readData[date] = item[1]
            })

            data.unread.forEach(item => {
                const date = item[0]
                unreadData[date] = item[1]
            })

            data.totalPerDay.forEach(item => {
                const date = item[0]
                totalData[date] = item[1]
            })

            // Obtém todas as datas e ordena para garantir que estão em sequência correta
            const allDates = Array.from(new Set([...Object.keys(totalData), ...Object.keys(readData), ...Object.keys(unreadData)]))

            // Popula as séries e categorias baseadas nas datas ordenadas
            allDates.forEach(date => {
                categories.push(
                    moment(date)
                        .utc(false)
                        .format(this.locale === 'pt-br' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'),
                )
                readSeries.push(readData[date] || 0)
                unreadSeries.push(unreadData[date] || 0)
                totalSeries.push(totalData[date] || 0)
            })

            this.chart.options.xaxis.categories = categories
            this.chart.series = [
                { name: this.$t('str.predicted'), data: totalSeries },
                { name: this.$t('str.read'), data: readSeries },
                { name: this.$t('str.unread'), data: unreadSeries },
            ]

            console.log('categories', categories)
            console.log('totalSeries', totalSeries)
            console.log('readSeries', readSeries)
            console.log('unreadSeries', unreadSeries)

            this.$refs.chart.updateOptions({
                xaxis: { categories: this.chart.options.xaxis.categories },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.apexcharts-legend {
    position: initial !important;
}

.apexcharts-legend-series {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px; /* Espaçamento entre itens da legenda */
}
</style>
